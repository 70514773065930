import React from "react";
import { useContext } from "react";
import { DEFAULT_LOCALE } from "./src/config";
import { LocaleContext } from "./src/localeContext";
import { TranslationContext } from "./src/translationsContext";

// Adds a class name to the body element

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleContext.Provider
      value={props?.pageContext?.locale || DEFAULT_LOCALE}
    >
      <TranslationContext.Provider value={props?.data?.allTranslation.nodes}>
        {element}
      </TranslationContext.Provider>
    </LocaleContext.Provider>
  );
};
