const productionHost = "www.mount-gold.com";
const productionApiHost = "lobby.miuratech.io";
const stagingApiHost = "lobby.dinotech.io";

const shared = {
  BRAND: "Mount Gold",
  ORG: "MT",
  IMAGEKIT_ENDPOINT: "https://ik.imagekit.io/tz66n6vb9",
  COOKIEBOT_CBID: "6259abaf-9b89-4433-a39b-1e4a502008d2",
};

const production = {
  ...shared,
  DINO_HOST: productionApiHost,
  AUTH_COOKIE_DOMAIN: productionHost,
  AUTH_COOKIE_SECURE: true,
  BASE_URL: () => "https://www.mount-gold.com",
  DINO_GRAPHQL_URL: `https://${productionApiHost}/lobby-graphql`,
  DINO_GRAPHQL_WS_URL: `wss://${productionApiHost}/lobby-graphql`,
};

const laxProduction = {
  ...production,
  AUTH_COOKIE_DOMAIN: undefined,
  AUTH_COOKIE_SECURE: true,
  BASE_URL: () =>
    typeof window !== "undefined"
      ? window.location.origin
      : "https://www.mount-gold.com",
};

const staging = {
  ...shared,
  DINO_HOST: stagingApiHost,
  AUTH_COOKIE_DOMAIN: undefined,
  AUTH_COOKIE_SECURE: true,
  // TODO: This should use an env variable for the ssr case to get
  // the URL of the gtsb.io or netlify build.
  BASE_URL: () =>
    typeof window !== "undefined"
      ? window.location.origin
      : "https://develop.mount-gold.com",
  DINO_GRAPHQL_URL: `https://${stagingApiHost}/lobby-graphql`,
  DINO_GRAPHQL_WS_URL: `wss://${stagingApiHost}/lobby-graphql`,
};

const local = {
  ...staging,
  AUTH_COOKIE_SECURE: false,
};

export const DEFAULT_LOCALE = "en";
//export const LOCALES = ["en", "en_CA", "fr_CA" || ""]
export const LOCALES = ["en", "fi"];

export type LocaleMapping = {
  [locale: string]: string;
};

export const FALLBACK_LOCALES = {
  en_CA: "en",
  fi: "en",
};

export const browserConfig = () => {
  if (typeof window !== "undefined") {
    try {
      const override = sessionStorage.getItem("config");
      switch (override) {
        case "production":
          return laxProduction;
        case "staging":
          return staging;
        case "local":
          return local;
      }
    } catch {}

    switch (window.location.hostname) {
      case productionHost:
        return production;
      case "localhost":
        return local;
      default:
        return staging;
    }
  } else {
    throw new Error("Can't use browser config in SSR mode");
  }
};

export const ssrConfig = () => {
  return process.env.GATSBY_ACTIVE_ENV === "production" ? production : staging;
};

export default () => {
  if (typeof window !== "undefined") {
    return browserConfig();
  } else {
    return ssrConfig();
  }
};
